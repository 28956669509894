<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem
                prop="name_en"
                :label="`${$t('name')} *`"
                v-if="selectedFieldLanguage === 'EN'"
                class="translation"
              >
                <ElInput
                  v-model="form.name_en"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #prepend>
                    <ElFormItem>
                      <ElSelect
                        v-model="selectedFieldLanguage"
                        :autocomplete="true"
                        :placeholder="$t('select_an_option')"
                        :fallback-placements="['top', 'bottom']"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                      >
                        <template #prefix>
                          <img
                            v-if="selectedFieldLanguageFlag"
                            :src="selectedFieldLanguageFlag.flag"
                          />
                        </template>
                        <el-option
                          v-for="item in languages"
                          :key="item.iso_code"
                          :label="item.label"
                          :value="item.iso_code"
                        />
                      </ElSelect>
                    </ElFormItem>
                  </template>
                </ElInput>
                <Icons
                  name="20px/translation"
                  @click="translateField('name_en')"
                />
              </ElFormItem>
              <ElFormItem
                prop="name_es"
                :label="`${$t('name')} *`"
                v-if="selectedFieldLanguage === 'ES'"
                class="translation"
              >
                <ElInput
                  v-model="form.name_es"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #prepend>
                    <ElFormItem>
                      <ElSelect
                        v-model="selectedFieldLanguage"
                        :autocomplete="true"
                        :placeholder="$t('select_an_option')"
                        :fallback-placements="['top', 'bottom']"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                      >
                        <template #prefix>
                          <img
                            v-if="selectedFieldLanguageFlag"
                            :src="selectedFieldLanguageFlag.flag"
                          />
                        </template>
                        <el-option
                          v-for="item in languages"
                          :key="item.iso_code"
                          :label="item.label"
                          :value="item.iso_code"
                        />
                      </ElSelect>
                    </ElFormItem>
                  </template>
                </ElInput>
                <Icons
                  name="20px/translation"
                  @click="translateField('name_es')"
                />
              </ElFormItem>
              <ElFormItem
                prop="name_fr"
                :label="`${$t('name')} *`"
                v-if="selectedFieldLanguage === 'FR'"
                class="translation"
              >
                <ElInput
                  v-model="form.name_fr"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #prepend>
                    <ElFormItem>
                      <ElSelect
                        v-model="selectedFieldLanguage"
                        :autocomplete="true"
                        :placeholder="$t('select_an_option')"
                        :fallback-placements="['top', 'bottom']"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                      >
                        <template #prefix>
                          <img
                            v-if="selectedFieldLanguageFlag"
                            :src="selectedFieldLanguageFlag.flag"
                          />
                        </template>
                        <el-option
                          v-for="item in languages"
                          :key="item.iso_code"
                          :label="item.label"
                          :value="item.iso_code"
                        />
                      </ElSelect>
                    </ElFormItem>
                  </template>
                </ElInput>
                <Icons
                  name="20px/translation"
                  @click="translateField('name_fr')"
                />
              </ElFormItem>
              <ElFormItem
                prop="name_de"
                :label="`${$t('name')} *`"
                v-if="selectedFieldLanguage === 'DE'"
                class="translation"
              >
                <ElInput
                  v-model="form.name_de"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #prepend>
                    <ElFormItem>
                      <ElSelect
                        v-model="selectedFieldLanguage"
                        :autocomplete="true"
                        :placeholder="$t('select_an_option')"
                        :fallback-placements="['top', 'bottom']"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                      >
                        <template #prefix>
                          <img
                            v-if="selectedFieldLanguageFlag"
                            :src="selectedFieldLanguageFlag.flag"
                          />
                        </template>
                        <el-option
                          v-for="item in languages"
                          :key="item.iso_code"
                          :label="item.label"
                          :value="item.iso_code"
                        />
                      </ElSelect>
                    </ElFormItem>
                  </template>
                </ElInput>
                <Icons
                  name="20px/translation"
                  @click="translateField('name_de')"
                />
              </ElFormItem>
              <ElFormItem
                prop="name_sv"
                :label="`${$t('name')} *`"
                v-if="selectedFieldLanguage === 'SV'"
                class="translation"
              >
                <ElInput
                  v-model="form.name_sv"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #prepend>
                    <ElFormItem>
                      <ElSelect
                        v-model="selectedFieldLanguage"
                        :autocomplete="true"
                        :placeholder="$t('select_an_option')"
                        :fallback-placements="['top', 'bottom']"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                      >
                        <template #prefix>
                          <img
                            v-if="selectedFieldLanguageFlag"
                            :src="selectedFieldLanguageFlag.flag"
                          />
                        </template>
                        <el-option
                          v-for="item in languages"
                          :key="item.iso_code"
                          :label="item.label"
                          :value="item.iso_code"
                        />
                      </ElSelect>
                    </ElFormItem>
                  </template>
                </ElInput>
                <Icons
                  name="20px/translation"
                  @click="translateField('name_sv')"
                />
              </ElFormItem>
              <ElFormItem
                prop="name_ar"
                :label="`${$t('name')} *`"
                v-if="selectedFieldLanguage === 'AR'"
                class="translation"
              >
                <ElInput
                  v-model="form.name_ar"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                >
                  <template #prepend>
                    <ElFormItem>
                      <ElSelect
                        v-model="selectedFieldLanguage"
                        :autocomplete="true"
                        :placeholder="$t('select_an_option')"
                        :fallback-placements="['top', 'bottom']"
                        :popper-options="getSelectPopperOptions()"
                        popper-class="custom-selector"
                      >
                        <template #prefix>
                          <img
                            v-if="selectedFieldLanguageFlag"
                            :src="selectedFieldLanguageFlag.flag"
                          />
                        </template>
                        <el-option
                          v-for="item in languages"
                          :key="item.iso_code"
                          :label="item.label"
                          :value="item.iso_code"
                        />
                      </ElSelect>
                    </ElFormItem>
                  </template>
                </ElInput>
                <Icons
                  name="20px/translation"
                  @click="translateField('name_ar')"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="reason" :label="$t('reason')">
                <ElSelect
                  v-model="form.reason"
                  :autocomplete="true"
                  :filterable="true"
                  style="width: 100%"
                  :placeholder="$t('select_a_reason')"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in signingReasons"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <ElFormItem prop="color" :label="$t('color')">
                <ElColorPicker
                  v-model="form.color"
                  @active-change="form.color = $event"
                />
              </ElFormItem>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, requiredIf, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_SIGNING_REASON from '~/graphql/signing-reasons/mutation/createSigningReason.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  name_es: '',
  name_en: '',
  name_fr: '',
  name_de: '',
  name_sv: '',
  name_ar: '',
  reason: '',
  color: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const signingReasons = getSigningReasons()
const languages = getLanguages()
const selectedFieldLanguage = ref('EN')
const {getTargetLang, getSourceLanguage,fieldsByLang} =
  getFormInitialVariables()
const rules = {
  name_es: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'ES'
    })
  },
  name_en: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'EN'
    })
  },
  name_fr: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'FR'
    })
  },
  name_de: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'DE'
    })
  },
  name_sv: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'SV'
    })
  },
  name_ar: {
    required: requiredIf(() => {
      return selectedFieldLanguage.value === 'AR'
    })
  },
  reason: {
    required
  },
  color: {
    required
  }
}

const v$ = useVuelidate(rules, form)

onMounted(() => {
  setFieldLanguage()
})

const inputRules = reactive({
  name_es: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'ES' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_en: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'EN' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_fr: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'FR' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_de: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'DE' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_sv: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'SV' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  name_ar: [
    {
      validator: (rule, value, callback) => {
        if (selectedFieldLanguage.value === 'AR' && !value) {
          return callback(t('name_is_required'))
        }
        return callback()
      }
    }
  ],
  reason: [
    {
      required: true,
      message: t('reason_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  color: [
    {
      required: true,
      message: t('color_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const selectedFieldLanguageFlag = computed(() => {
  return languages.find((item) => item.iso_code === selectedFieldLanguage.value)
})

const submit = async (formEl) => {
  v$.value.$touch()
    await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const variables = {
    name_es: form.name_es,
    name_en: form.name_en,
    name_fr: form.name_fr,
    name_de: form.name_de,
    name_sv: form.name_sv,
    name_ar: form.name_ar,
    color: form.color,
    reason: form.reason
  }
  const response = await mutation(CREATE_SIGNING_REASON, variables)
  if (response.error) {
    loading.value = false
    if (response.error.message.includes('input.name')) {
      $showError(t('signing_reason_already_exists'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createSigningReason ?? null)
    loading.value = false
  }
}

const setFieldLanguage = () => {
  if (getAuthUser()?.value?.language?.toLowerCase() === 'en') {
    selectedFieldLanguage.value = 'EN'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'es') {
    selectedFieldLanguage.value = 'ES'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'fr') {
    selectedFieldLanguage.value = 'FR'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'de') {
    selectedFieldLanguage.value = 'DE'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'sv') {
    selectedFieldLanguage.value = 'SV'
  } else if (getAuthUser()?.value?.language?.toLowerCase() === 'ar') {
    selectedFieldLanguage.value = 'AR'
  }
}

const translateField = async (field) => {
  try {
    loading.value = true
    const response = await mutationTranslation(
      {
        text: form[field],
        source_lang: getSourceLanguage(field).name,
        target_lang: getTargetLang(field)
      },
      t,
      getSourceLanguage(field).name
    )
    const fieldByLang = fieldsByLang(field);
    if (response?.length) {
      response.forEach((item) => {
          const fieldName = fieldByLang[item.target_lang];
          if (fieldName) {
         form[fieldName] = item.translated_text;
        }
      });
    }
  } catch (err) {
    $showError(err, t)
    $sentry(err, 'translateField')
  } finally {
    loading.value = false
  }
}
</script>
